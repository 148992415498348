<template>
  <div class="template-datas">
    <div class="wrapper">
      <div v-if="dynamicHeaders.length && !isLoadingProp" class="wrapper__body">
        <z-pagination
          :auto-pagination="false"
          icon-previous="mdi-chevron-left"
          icon-next="mdi-chevron-right"
          :items-per-page="20"
          :number-of-pages="info.totalPages"
          :current-page="info.page"
          :items="info.data"
          @change:page="alterPageNumber"
          @click:next="goPage"
          @click:previous="backPage"
        >
          <template #data="{ paginatedItems }">
            <div class="table-wrapper">
              <z-table
                class="table-dynamic"
                :headers="dynamicHeaders"
                :items="paginatedItems"
                icon-next="mdi-chevron-right"
                icon-previous="mdi-chevron-left"
                last-fixed-column="actions"
                :last-fixed-column-shadow="0"
                @sort="sortTable"
                :key="customKey"
              >
                <template #no-data>
                  <div class="no-data-table">
                    <h3>Sem dados disponíveis.</h3>
                  </div>
                </template>

                <template
                  v-for="data of dynamicHeaders"
                  v-slot:[`item.${data.value}`]="{ item }"
                >
                  <!-- TEMPLATE DE CHIPS -->

                  <div
                    v-if="data.value == 'tags'"
                    :key="data.id"
                    class="d-flex"
                    style="
                      gap: 0.5rem;
                      max-width: 500px;
                      flex-wrap: wrap;
                      padding: 0.5rem 0;
                    "
                  >
                    <v-chip
                      v-for="(item, idx) in item.tags"
                      :key="idx"
                      small
                      color="primary"
                      style="padding: 0 0.35rem"
                    >
                      {{ item }}
                    </v-chip>
                  </div>

                  <div
                    v-if="data.value != 'actions' && data.value != 'tags'"
                    :key="data.id"
                  >
                    <span v-if="!isEditingRow || rowToEdit?.id !== item.id">
                      {{ item[data.value] }}
                    </span>

                    <z-input
                      v-if="isEditingRow && rowToEdit?.id === item.id"
                      v-model="item[data.value]"
                      :hideDetails="true"
                      :readonly="
                        data.value.toLowerCase() == 'id' ||
                        data.value.toLowerCase() == 'zooxuserid'
                      "
                      type="text"
                    />
                  </div>

                  <!-- TEMPLATE DE AÇÕES -->
                  <div
                    v-if="data.value == 'actions'"
                    class="actions"
                    :key="data.id"
                  >
                    <v-icon
                      color="primary"
                      v-if="!isEditingRow || rowToEdit?.id !== item.id"
                      @click="setItemToEdit(item)"
                    >
                      $edit_rounded</v-icon
                    >
                    <v-icon
                      color="primary"
                      v-if="isEditingRow && rowToEdit?.id == item.id"
                      @click="openConfirmationModal(item)"
                    >
                      $save_rounded
                    </v-icon>

                    <v-icon
                      color="primary"
                      v-if="isEditingRow && rowToEdit?.id == item.id"
                      @click="(isEditingRow = false), resetRowInfo(item)"
                    >
                      $close_rounded
                    </v-icon>

                    <v-icon
                      color="primary"
                      @click="openDeleteModal(item._id)"
                    >
                      $delete_outline_rounded</v-icon
                    >

                    <v-icon
                      color="primary"
                      @click="openUserDetail(item)"
                    >
                      $eye_open</v-icon
                    >
                  </div>
                </template>
              </z-table>
            </div>
          </template>
        </z-pagination>
      </div>
      <div
        v-if="!dynamicHeaders.length && !isLoadingProp"
        class="wrapper__no-data"
      >
        <span>Sem dados</span>
      </div>
      <div v-if="isLoadingProp" class="wrapper__loading">
        <z-loading />
      </div>
    </div>

    <v-dialog v-model="dialogDelete" width="400">
      <v-form ref="formDialog" @submit.prevent="saveAndClose">
        <div class="dialog" style="background: white; padding: 1rem">
          <div class="dialog__title mb-3" style="text-align: center">
            <strong>Tem certeza que deseja excluir esses dados?</strong>
          </div>

          <div
            class="dialog--actions d-flex align-center justify-center"
            style="gap: 0.9rem"
          >
            <z-btn
              text="Cancelar"
              color="primary"
              :rounded="true"
              @click="cancelAndReset"
            />
            <z-btn
              text="Excluir"
              primary
              color="primary"
              :rounded="true"
              :is-loading="loadingSave"
              @click="deleteAndClose"
            />
          </div>
        </div>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogSave" width="400">
      <v-form ref="formDialog" @submit.prevent="saveAndClose">
        <div class="dialog" style="background: white; padding: 1rem">
          <div class="dialog__title mb-3" style="text-align: center">
            <strong>Tem certeza que deseja atualizar esses dados?</strong>
          </div>

          <div
            class="dialog--actions d-flex align-center justify-center"
            style="gap: 0.9rem"
          >
            <z-btn
              text="Cancelar"
              color="primary"
              :rounded="true"
              @click="cancelAndReset"
            />
            <z-btn
              text="Salvar"
              primary
              color="primary"
              :rounded="true"
              :is-loading="loadingSave"
              @click="saveAndClose"
            />
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
export default {
  props: {
    infoProp: { type: Object, default: null },
    isLoadingProp: { type: Boolean, default: false },
    choosenConnectionIdProp: { type: String, default: "" },
  },
  data() {
    return {
      customKey: 0,
      info: {},
      http: new ApiClient(),
      loadingSave: false,
      dialogSave: false,
      dialogDelete: false,
      deleteId: "",
      sortDesc: false,
      displayData: false,
      dynamicHeaders: [],
      listT: [],
      isEditingRow: false,
      rowToEdit: null,
      actualEditedRow: null,
    };
  },
  watch: {
    infoProp: {
      async handler(nv) {
        if (nv) {
          this.dynamicHeaders = await this.mountHeaders(nv?.data || []);
          this.info = this.infoProp;
        }
      },
      deep: true,
      immediate: true,
    },
    isLoadingProp(nv) {
      if (nv) {
        if (this.rowToEdit) {
          this.resetRowInfo(this.rowToEdit);
          this.isEditingRow = false;
        } else {
          this.isEditingRow = false;
        }
      }
    },
  },
  async created() {},
  methods: {
    resetRowInfo(rowInfo) {
      const IDX = this.info.data.findIndex((d) => d.id == rowInfo.id);
      if (IDX >= 0) {
        this.info.data[IDX] = this.rowToEdit;
        this.customKey++;
      }
    },
    openUserDetail(item){
      console.log(item);

      window.open(`/detailUser?email=${item.email}&phone=${item.phone}`)
    },  
    openConfirmationModal(rowUpdated) {
      this.actualEditedRow = rowUpdated;
      this.dialogSave = true;
    },
    openDeleteModal(id) {
      this.deleteId = id;
      this.dialogDelete = true;
    },

    async deleteAndClose() {
      const OBJ = {};
      OBJ.databaseConfigId = this.choosenConnectionIdProp;
      OBJ.deleteId = this.deleteId;
      // console.log(OBJ)

      try {
        this.loadingSave = true;
        const response = await this.http.post(
          "audienceData/deleteAudienceData",
          OBJ
        );
        if (response) {
          this.$toast.success("Dados atualizados com sucesso");
          this.dialogDelete = false;
          this.$emit("getNewPage", this.info.page);
        }
        this.loadingSave = false;
      } catch (error) {
        this.$toast.error("Erro ao atualizar informações");
        this.loadingSave = false;
      }
    },

    async saveAndClose() {
      const OBJ = {};
      OBJ.databaseConfigId = this.choosenConnectionIdProp;
      OBJ.data = { ...this.actualEditedRow };
      // console.log(OBJ)

      try {
        this.loadingSave = true;
        const response = await this.http.post(
          "audienceData/editAudienceData",
          OBJ
        );
        if (response) {
          this.$toast.success("Dados atualizados com sucesso");
          this.actualEditedRow = null;
          this.dialogSave = false;
          this.isEditingRow = false;
        }
        this.loadingSave = false;
      } catch (error) {
        this.$toast.error("Erro ao atualizar informações");
        this.loadingSave = false;
      }
    },

    cancelAndReset() {
      // this.resetRowInfo(this.actualEditedRow) // FUNCIONAL BASTA VERIFICAR SE É ESSA FUNCIONALIDADE MESMO QEU SERÁ NECESSÁRIA
      this.dialogSave = false;
      this.dialogDelete = false;
      // this.isEditingRow = false;
    },
    setItemToEdit(row) {
      this.rowToEdit = { ...row };
      this.isEditingRow = !this.isEditingRow;
    },
    sortTable(nameToSort) {
      this.sortDesc = !this.sortDesc;
      const ORDER = this.sortDesc ? "ASC" : "DESC";
      this.$emit("sortTable", nameToSort, ORDER);
    },
    alterPageNumber(page) {
      this.$emit("getNewPage", page);
    },
    goPage() {
      if (this.info.page < this.info.totalPages)
        this.$emit("getNewPage", this.info.page + 1);
    },
    backPage() {
      if (this.info.page > 1) this.$emit("getNewPage", this.info.page - 1);
    },
    mountHeaders(obj) {
      // const chip_mock = [
      //   "allianz seguradora s/a",
      //   "akad seguros s/a",
      //   "aligo seguros",
      //   "alfa seguros e previdência s/a",
      //   "aig seguros brasil s.a",
      //   "amil assistência médica internacional s.a",
      //   " assim saude",
      //   "austral seguradora",
      //   "avla seguros brasil s/a",
      //   "axa seguros",
      // ];

      // insere id para remoção de erro, caso objetos não tenham id
      for (const data of obj) {
        if (!data.id) data.id = this.generateRandomId();
        //  data.tags = chip_mock;
      }

      const HEADERS = [];
      // PEGAR SEMPRE O PRIMEIRO ELEMENTO SE ELE EXISTIR, VISTO QUE TODOS OS OBJETO TERÃO A MESMA ESTRUTURA
      const FIRST_OBJECT = obj[0] || {};
      const KEYS = Object.keys(FIRST_OBJECT);

      for (const TITLE of KEYS) {
        HEADERS.push({
          text: TITLE,
          value: TITLE,
          sortable: true,
          // width: "14.5vw"
        });
      }

      if (HEADERS.length > 0) {
        HEADERS.push({
          text: "",
          value: "actions",
          width: "5vw",
        });
      }
      return HEADERS;
    },
    mountBody(obj) {
      const BODY = [];
      for (const [key, value] of Object.entries(obj.data)) {
        BODY.push({
          id: this.generateRandomId(),
          [key]: value,
        });
      }
      return BODY;
    },
    mountList(obj) {
      const LIST = [];
      for (const [key, value] of Object.entries(obj.data)) {
        LIST.push({
          id: this.generateRandomId(),
          title: key,
          content: value,
          [key]: value,
        });
      }
      return LIST;
    },
    generateRandomId() {
      const PART_1 = Math.floor(Math.random() * 245);
      const PART_2 = Date.now();
      const PART_3 = Math.random().toString(36).substring(2);
      return `${PART_1}${PART_2}${PART_3}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.template-datas {
  height: calc(100vh - 48px);
  .wrapper {
    height: 100%;
    padding-bottom: 10rem;

    &__body {
      height: 100%;
      // display: flex;
      //  // justify-content: center;
      //   overflow-x: auto !important;

      .table-wrapper {
        height: 450px !important;
        overflow-y: auto;
        overflow-x: auto !important;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
        }

        @media (min-width: 1550px) {
          height: auto !important;
          max-height: 70vh !important;
        }
      }
      .no-data-table {
        text-align: center;
      }
      // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          // position: relative!important;
          // border: 5px solid yellow !important;
          // min-width: 100vw !important;
          // overflow-x: auto   !important;
          // @include trackScrollBar;
          table {
            // border: 2px solid blue;
            // overflow: auto ;
            // // position: relative !important;
            // border: 5px solid blue !important;
            th {
              &:not(:last-child) {
                min-width: 10vw !important;
              }

              &:first-child {
                // position: sticky !important;
                // position: -webkit-sticky !important;
                // left: 0 !important;
                // background: white !important;
                // z-index: 10 !important;
                // -webkit-box-shadow: 3px 2px 12px -8px rgba(0, 0, 0, 0.25);
                // -moz-box-shadow: 3px 2px 12px -8px rgba(0, 0, 0, 0.25);
                // box-shadow: 3px 2px 12px -8px rgba(0, 0, 0, 0.25);
              }
            }
            td {
              &:first-child {
                // border: 2px solid red;
                // position: sticky !important;
                // position: -webkit-sticky !important;
                // right: 0 !important;
                // left: 0 !important;
                // z-index: 10 !important;
                // background: white !important;
                // -webkit-box-shadow: 3px 2px 12px -8px rgba(0, 0, 0, 0.25);
                // -moz-box-shadow: 3px 2px 12px -8px rgba(0, 0, 0, 0.25);
                // box-shadow: 3px 2px 12px -8px rgba(0, 0, 0, 0.25);
              }
            }
          }
        }
        ::v-deep .v-data-table__wrapper {
          overflow-x: initial !important;
          overflow: unset !important;
          min-width: max-content !important;
        }
      }
    }
    &__loading {
      height: 50vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__no-data {
      height: 40vh;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $z-s-1;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 15px;
      span {
        font-weight: 600;
        color: #1976d2;
        font-size: 18px;
      }
    }
  }
}
</style>
